/* eslint-disable no-console */
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Observable, Subscription, of, BehaviorSubject, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, filter, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import gql from 'graphql-tag';
import { plainToClass, classToPlain, Expose } from 'class-transformer';

import { User, EnrollmentStatus, UserZone, PredefinedUserZone } from './auth.models';
import { AccountingPeriodType } from '../accounting/accounting-period.models';

import { ConfigService } from '../general/config.service';
import { RestService } from '../general/rest.service';
import { PayUService } from '../general/payu.service';
import { toObservable, AuthHeaderProvider, intToDate, dateToInt, dateToYYYYMM, isNullOrUndefined } from '@saliente/library';

import { AUTH_ROUTES } from '../../auth/auth.routes.constants';
import { ApolloQueryResult } from '@apollo/client/core';

//#region GraphQL queries
const userQuery2 = gql`
	query user {
		auth {
			GetCurrentUserDataUsingGET {
				externalId
				userLogin
				email
				phone
				firstName
				lastName
				showHelp
				created
				isMfaEnabled
			}
		}
		accounts {
			enrollmentStatus: getUserEnrollmentStatusUsingGET {
				pendingRegistrationEid
				pendingRegistrationType
				status
			}
			zones: getUiZonesWithExpandedRolesUsingGET {
				zones {
					zoneCode
					show
					clients {
						clientEid
						name
						nameNA
						fiscalNumber
						status
						accountingPartnerCode
						suspended
						inactive
					}
				}
				userRolesExpanded {
					expandedRoles
				}
			}
		}
	}
`;
const userProfileFields = `
			GetCurrentUserDataUsingGET {
				externalId
				userLogin
				email
				phone
				firstName
				lastName
				showHelp
				created
				isMfaEnabled
			}
`;
const userProfileQuery= gql`
	query user {
		auth {
      ${userProfileFields}
		}
  }
`;
const userQueryEx2 = gql`
	query user {
		auth {
      ${userProfileFields}
		}
		accounts {
			enrollmentStatus: getUserEnrollmentStatusUsingGET {
				pendingRegistrationEid
				pendingRegistrationType
				status
			}
			zones: getUiZonesUsingGET {
				zones {
					zoneCode
					show
					clients {
						clientEid
						name
						nameNA
						fiscalNumber
						status
						accountingPartnerCode
						suspended
						inactive
					}
				}
			}
		}
	}
`;
export const Accounts_GetUserRolesExpandedForClientUsingGetDocument = gql`
	query accounts_getUserRolesExpandedForClientUsingGET($clientEid: String) {
		accounts {
			userRolesExpanded: getUserRolesExpandedForClientUsingGET(clientEid: $clientEid) {
				expandedRoles
			}
		}
	}
`;
export type AccountsGetUserRolesExpandedForClientUsingGetArgs = {
	clientEid?: string;
};

class Auth_UserDataUpdateDtoInput {
	@Expose() email: string;
	@Expose() firstName: string;
	@Expose() lastName: string;
	@Expose() phone: string;
	static fromUser(user: User): Auth_UserDataUpdateDtoInput {
		const ret: Auth_UserDataUpdateDtoInput = plainToClass(Auth_UserDataUpdateDtoInput, user, { excludeExtraneousValues: true });
		return ret;
	}
}
export class Auth_UserDataUpdateResponseRepresentationMutation {
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	isEmailChanged: boolean;
	isPhoneChanged: boolean;
}
const updateUserDataMutationText = gql`
	mutation updateMyData($userEid: String, $user: auth_UserDataUpdateDTOInput!) {
		auth {
			UpdateUserDataUsingPUT(userEid: $userEid, data: $user) {
				email
				firstName
				lastName
				phone
				isEmailChanged
				isPhoneChanged
			}
		}
	}
`;

const updateMyDataMutationText = gql`
	mutation updateUserData($data: auth_UserDataPatchRepresentationInput!) {
		auth {
			patchUserDataUsingPATCH(data: $data) {
				Message
			}
		}
	}
`;

const changeMyPasswordMutationText = gql`
	mutation changeMyPassword($data: auth_ChangePasswordUpdateRepresentationInput!) {
		auth {
			changeMyPasswordUsingPUT(data: $data) {
				Message
			}
		}
	}
`;

const companyInfoQueryText = gql`
	query companyInfo($clientEid: String!, $accountingPeriodType: String!) {
		accounts {
			getLastClosedAccountingPeriodUsingGET(clientEid: $clientEid, accountingPeriodType: $accountingPeriodType) {
				period
			}
		}
	}
`;

const companyDataQueryText = gql`
	query companyInfo($fiscalNumber: String!, $date: Int!) {
		accounts {
			companyData: getCompanyRoDataUsingGET(fiscalNumber: $fiscalNumber, forDate: $date) {
				isVATPayer: vatPayer
			}
		}
	}
`;

const unixTimeQueryText = gql`
	query unixTime {
		accounts {
			getServerUnixTimeUsingGET {
				unixTime
			}
		}
	}
`;
//#endregion

let oidDiscovered = false;
let oidDiscoverPromise: Promise<boolean>;
let oidConfig: any;

let userIsAvailable = false;

/*------------------------------------------------------------*/
export const Billing_GetContractExceedingUsingGetDocument = gql`
	query billing_getContractExceedingUsingGET($clientEid: String) {
		billing {
			getContractExceedingUsingGET(clientEid: $clientEid) {
				exceedingValueNoVAT
				exceeding
			}
		}
	}
`;
export type Billing_GetContractExceedingUsingGetQueryVariables = {
	clientEid?: string;
};
export type Billing_SubscriptionExceedingRepresentation = {
	exceedingValueNoVAT?: number;
	exceeding?: boolean;
};
export type Billing_GetContractExceedingUsingGetQuery = {
	billing?: {
		getContractExceedingUsingGET?: Billing_SubscriptionExceedingRepresentation;
	};
};

@Injectable()
export class AuthService extends AuthHeaderProvider {
	private loggingOut = false;
	private loggedIn = new EventEmitter();

	public isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
	public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

	private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
	public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

	private _user: User;
	private userSelectedCompanyIdSubscription: Subscription;
	get user(): User {
		return this._user;
	}
	set user(value: User) {
		if (this.userSelectedCompanyIdSubscription) {
			this.userSelectedCompanyIdSubscription.unsubscribe();
		}
		this._user = value;
		if (this._user) {
			this.userSelectedCompanyIdSubscription = this._user.selectedCompanyIdObservable.subscribe(() => {
				this.checkUserCompanyStatus();
				this.getCompanyInfo();
			});
		}
	}

	public globalWarningMessage: string;

	//#region Properties

	public get customQueryParams() {
		return this.oauthService.customQueryParams;
	}
	public set customQueryParams(value) {
		this.oauthService.customQueryParams = value;
	}

	public get isLoggedIn(): boolean {
		return this.oauthService.hasValidIdToken() && !!this.user;
	}

	public get isEnrolled(): boolean {
		return this.user && (this.user.enrollmentStatus.name === 'Complete' || this.user.enrollmentStatus.name === 'WaitingForBO');
	}

	get lastClosedAccountingPeriod() {
		return this.user.selectedCompany?.lastClosedAccountingPeriod;
	}

	get firstDayAfterLastClosedAccountingPeriod() {
		return this.user.selectedCompany?.firstDayAfterLastClosedAccountingPeriod;
	}

	public get claims(): any {
		const claims = this.oauthService.getIdentityClaims();
		if (!claims) {
			return null;
		}
		return claims;
	}

	public get userName(): string {
		if (this.user) {
			return this.user.fullName;
		}
		return '';
	}

	public get userEmail(): string {
		if (this.user) {
			return this.user.email;
		}
		return '';
	}

	public get userNameInitialLetters(): string {
		if (this.user) {
			return `${this.user.firstName != undefined ? this.user.firstName.substr(0, 1) : ''}${this.user.lastName != undefined ? this.user.lastName.substr(0, 1) : ''}`.toUpperCase();
		}
		return '';
	}

	get selectedZoneCode(): string {
		if (this.user) {
			return this.user.selectedZoneCode;
		}
		return null;
	}
	set selectedZoneCode(value: string) {
		if (this.user) {
			this.user.selectedZoneCode = value;
		}
	}

	get selectedZoneDisplayName() {
		return this.getZoneDisplayName(this.selectedZoneCode);
	}

	public get displayZones(): string[] {
		if (this.user) {
			return this.user.displayZones;
		}
		return [];
	}

	//#endregion

	constructor(
		public readonly oauthService: OAuthService,
		private configService: ConfigService,
		private restService: RestService,
		private http: HttpClient,
		private injector: Injector,
		public toastr: ToastrService,
		private router: Router,
		private payUService: PayUService // private appMenuService: AppMenuService
	) {
		super();
	}

	public configure(authConfig: any) {
		oidDiscovered = false;
		oidDiscoverPromise = null;
		oidConfig = authConfig;

		// this.discover();

		this.oauthService.configure(oidConfig);
		this.oauthService.tokenValidationHandler = new JwksValidationHandler();

		// Optional
		// fix, on refresh, if the token is still valid the refresh timeout will be greater that expiration timeout
		const oas: any = this.oauthService;
		if (!isNaN(oas.getIdTokenStoredAt())) {
			oas._storage.setItem('id_token_stored_at', Date.now());
		}

		this.oauthService.setupAutomaticSilentRefresh();
		this.oauthService.events.subscribe((e) => {
			console.debug('oauth/oidc event', e);
		});

		this.oauthService.events.pipe(filter((e) => e.type === 'session_terminated' || e.type === 'silent_refresh_error')).subscribe((e) => {
			if (e.type === 'session_terminated') console.debug('Your session has been terminated!');
			else if (e.type === 'silent_refresh_error') console.debug('Your token has expired!');
			this.logout();
		});

		this.oauthService.events.subscribe((_) => {
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
		});

		this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((e) => {
			this.createCookie().subscribe();
			setTimeout(() => {
				this.payUService.updateBrowserInfo().pipe(take(1)).subscribe();
			}, 10000);
			/*- this.payUService.updateBrowserInfo().pipe(take(1)).subscribe(); */
		});

		this.runInitialLoginSequence();
	}

	public runInitialLoginSequence(): Promise<void> {
		return this.oauthService
			.loadDiscoveryDocument()
			.then(() => this.oauthService.tryLogin())
			.then(() => {
				if (this.oauthService.hasValidAccessToken()) {
					return Promise.resolve();
				}
				return this.oauthService
					.silentRefresh()
					.then(() => Promise.resolve())
					.catch((result) => {
						const errorResponsesRequiringUserInteraction = ['interaction_required', 'login_required', 'account_selection_required', 'consent_required'];

						if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
							console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
							this.login();
							return Promise.resolve();
						}

						return Promise.reject(result);
					});
			})

			.then(() => {
				console.log('************************************ in runInitialLoginSequence');
				return this.ensureUserEx().toPromise();
			})
			.then(() => {
				this.isDoneLoadingSubject$.next(true);

				let authState: any = this.oauthService.state;
				if (authState) {
					try {
						authState = JSON.parse(authState);
						if (authState.redirect && authState.redirect != '/') {
							this.router.navigateByUrl(authState.redirect);
							this.oauthService.state = '';
							return;
						}
					} catch {}
				}

				// this.router.navigate(['/home']);
			})
			.catch(() => this.isDoneLoadingSubject$.next(true));
	}

	// private __discover__(): Promise<boolean> | boolean {
	//     if (oidConfig) {
	//         if (!oidDiscovered) {
	//             if (!oidDiscoverPromise) {
	//                 this.oauthService.configure(oidConfig);
	//                 this.oauthService.tokenValidationHandler = new JwksValidationHandler();

	//                 oidDiscoverPromise = this.oauthService.loadDiscoveryDocument()
	//                     .then(() => {
	//           this.oauthService.clearHashAfterLogin = false;
	//                         return this.oauthService
	//                             .tryLogin({})
	//                             .then(() => {
	//                                 oidDiscovered = true;
	//                                 return oidDiscovered;
	//                             });
	//                     }).catch((err) => {
	//                         console.error('AuthService, could not discover OAuth service: ' + err.stack);
	//                         this.configService.page500();
	//                         return false;
	//                     });
	//             }
	//             return oidDiscoverPromise;
	//         }
	//     } else {
	//         console.error('AuthService, not configuration found for OAuth discovery!');
	//         this.configService.page500();
	//     }
	//     return !!oidConfig;
	// }

	// public discover() {
	//     return this.restService
	//         .query(
	//             {
	//                 query: unixTimeQueryText,
	//                 fetchPolicy: 'network-only'
	//             }, { error: false, spinner: false })
	//         .pipe(
	//             catchError(() => { return of(null); }),
	//             switchMap((response: any) => {
	//                 if (response) {
	//                     const serverUnixTime = response.data.accounts.getServerUnixTimeUsingGET.unixTime;

	//                     if (Math.abs(serverUnixTime - Date.now() / 1000) < 600) {
	//                         return toObservable(this.__discover__());
	//                     }
	//                     this.configService.pageUnixTimeError();
	//                 }
	//                 return of(false);
	//             })
	//         );
	// }

	private createCookie() {
		return this.http
			.get('/authorize', {
				headers: this.authHeader(),
			})
			.pipe(
				catchError((error: any): any => {
					return of(null);
				})
			);
	}

	public authHeaderValue(): string {
		if (this.oauthService.hasValidIdToken()) {
			return 'Bearer ' + this.oauthService.getIdToken();
		}
		return null;
	}

	public authHeader(): any {
		if (this.oauthService.hasValidIdToken()) {
			return { authorization: this.authHeaderValue() };
		}
		return null;
	}

	public getUser(): Observable<User> {
		if (this.user) {
			return of(this.user);
		}
		return this.loggedIn.pipe(
			map(() => {
				return this.user;
			})
		);
	}

	public getSelectedCompanyId(): Observable<string> {
		return this.getUser().pipe(
			switchMap((user) => {
				if (user) {
					return user.selectedCompanyIdObservable;
				}
				return of(null);
			})
		);
	}

	public getSelectedZoneCode(): Observable<string> {
		return this.getUser().pipe(
			switchMap((user) => {
				if (user) {
					return user.selectedZoneCodeObservable;
				}
				return of(PredefinedUserZone.None);
			})
		);
	}

	private processRoles(roles: any): any {
		const result: any = [];
		const flatRoles: any = {};
		roles.forEach((role: string) => {
			const clientRole = role.split(':');
			let roleCode: string;
			let isClientRole: boolean;
			let clientEid: string;
			if (clientRole.length === 2) {
				isClientRole = true;
				clientEid = clientRole[0];
				roleCode = clientRole[1];
			} else {
				isClientRole = false;
				roleCode = clientRole[0];
				clientEid = null;
			}
			let flatRole: any = flatRoles[roleCode];
			if (!flatRole) {
				flatRole = flatRoles[roleCode] = {
					code: roleCode,
					isClientRole,
				};
				result.push(flatRole);
			}
			if (isClientRole) {
				flatRole.clients = flatRole.clients || [];
				flatRole.clients.push(clientEid);
			}
		});
		return result;
	}

	private checkUserCompanyStatus(): void {
		this.globalWarningMessage = null;
		if (this.user && this.user.selectedCompany && this.user.selectedCompanyId) {
			if (this.selectedZoneCode === PredefinedUserZone.Administrator /*&& this.isInRole('ZEUS_MARKER')*/) {
				setTimeout(() => {
					this.getContractExceeding().subscribe(
						(exceeding) => {
							if (exceeding.billing.getContractExceedingUsingGET.exceeding) {
								this.toastr.warning(
									'<h5>Suma de plata pentru abonamentul tau pe luna viitoare a depasit cu minim 10% valoarea abonamentului de baza</h5> Pentru detalii vezi sectiunea Abonamente',
									null,
									{
										enableHtml: true,
									}
								);
							}
						},
						(err) => {}
					);
				}, 10000);
				/* 				(async (): Promise<void> => {
					const exceeding = await this.getContractExceedingLocal();
					if (exceeding.exceeding) {
						this.toastr.warning(
							'<h5>Suma de plata pentru abonamentul tau pe luna viitoare a depasit cu minim 10% valoarea abonamentului de baza</h5> Pentru detalii vezi sectiunea Abonamente',
							null,
							{
								enableHtml: true,
							}
						);
					}
				})();
 */
			}
			if (this.user.selectedCompany.suspended) {
				this.globalWarningMessage = 'Contul tău este suspendat pentru neplată.';
				this.toastr.error(
					'<h5>Contul tău este suspendat pentru neplată.</h5> Te rog să alimentezi cardul sau să introduci un nou card în secțiunea Abonament > Modalități de plată.',
					null,
					{
						enableHtml: true,
						disableTimeOut: true,
					}
				);
			} else if (!this.user.selectedCompany.suspended) {
				this.globalWarningMessage = null;
				this.toastr.clear();
			} else if (this.user.selectedCompany.status === 'WaitingForBO') {
				this.globalWarningMessage = 'Înrolarea, verificările și configurările inițiale sunt în curs';
				this.toastr.warning(
					'<h5>Înrolarea ta este în curs de procesare.</h5> După ce închidem contabil prima lună putem considera că verificările/configurările inițiale au fost terminate.',
					null,
					{
						enableHtml: true,
					}
				);
			}
		}
	}

	public getCompanyInfo() {
		if (this.user.selectedCompanyId) {
			this.restService
				.query(
					{
						query: companyInfoQueryText,
						fetchPolicy: 'network-only',
						variables: {
							clientEid: this.user.selectedCompanyId,
							accountingPeriodType: AccountingPeriodType.Accounting,
						},
					},
					{ error: false, spinner: false }
				)
				.pipe(
					map((response: any) => {
						if (response) {
							//this.user.selectedCompany.lastClosedAccountingPeriod = intToDate(response.data.accounts.getLastClosedAccountingPeriodUsingGET.forDate);
							this.user.selectedCompany.lastClosedAccountingPeriod = response.data.accounts.getLastClosedAccountingPeriodUsingGET.period;
							this.user.selectedCompany.firstDayAfterLastClosedAccountingPeriod = this.getFirstDateAfterLastClosedAccountingPeriod(
								response.data.accounts.getLastClosedAccountingPeriodUsingGET.period
							);
						} else {
							this.user.selectedCompany.lastClosedAccountingPeriod = null;
							this.user.selectedCompany.firstDayAfterLastClosedAccountingPeriod = null;
						}
						//this.user.selectedCompany.lastClosedAccountingPeriod = this.user.selectedCompany.lastClosedAccountingPeriod || new Date(0);
						this.user.selectedCompany.lastClosedAccountingPeriod = this.user.selectedCompany.lastClosedAccountingPeriod || dateToYYYYMM(new Date());
						this.user.selectedCompany.firstDayAfterLastClosedAccountingPeriod = this.getFirstDateAfterLastClosedAccountingPeriod(
							this.user.selectedCompany.lastClosedAccountingPeriod
						);

						return response;
					}),
					catchError((error) => {
						//this.user.selectedCompany.lastClosedAccountingPeriod = new Date(0);
						this.user.selectedCompany.lastClosedAccountingPeriod = dateToYYYYMM(new Date());
						this.user.selectedCompany.firstDayAfterLastClosedAccountingPeriod = this.getFirstDateAfterLastClosedAccountingPeriod(
							this.user.selectedCompany.lastClosedAccountingPeriod
						);
						return of(null);
					}),
					take(1)
				)
				.subscribe();

			// the call above may throw 404 error and we will not get the additional properties requested
			this.restService
				.query(
					{
						query: companyDataQueryText,
						fetchPolicy: 'network-only',
						variables: {
							fiscalNumber: this.user.selectedCompany.fiscalNumber,
							date: dateToInt(new Date()),
						},
					},
					{ error: false, spinner: false }
				)
				.pipe(
					map((response: any) => {
						this.user.selectedCompany.isVATPayer = response.data.accounts.companyData.isVATPayer;
						return response;
					}),
					catchError((error) => {
						return of(null);
					}),
					take(1)
				)
				.subscribe();
		} else {
			//this.user.selectedCompany.lastClosedAccountingPeriod = new Date(0);
			this.user.selectedCompany.lastClosedAccountingPeriod = dateToYYYYMM(new Date());
			this.user.selectedCompany.firstDayAfterLastClosedAccountingPeriod = this.getFirstDateAfterLastClosedAccountingPeriod(this.user.selectedCompany.lastClosedAccountingPeriod);
		}
	}

	public getFirstDateAfterLastClosedAccountingPeriod(period: number): Date {
		var firstDayOfThePeriod = intToDate(period * 100 + 1);
		return new Date(firstDayOfThePeriod.getFullYear(), firstDayOfThePeriod.getMonth() + 1, 1);
	}

	// public ensureUser(): Observable<boolean> {
	//   return this.ensureUserEx().pipe(
	//     map((result) => {
	//       return result != "notok";
	//     })
	//   );
	// }
	public refreshCurrentUserProfile(): Observable<User> {
		if (isNullOrUndefined(this.user)) {
			/*- when doing page refresh while user profile is active we might not have already the user data filled in*/
			return this.getUser();
		}
		return this.restService
			.query({
				query: userProfileQuery,
				fetchPolicy: 'network-only',
			})
			.pipe(
				catchError((error) => {
					console.debug('refreshCurrentUserProfile.query error: ' + error.toString());
					console.error(error);
					return of(null);
				}),
				switchMap((res: any) => {
					console.debug('refreshCurrentUserProfile.query result.Start: ' + new Date());
					if (res) {
						const user = res.data.auth.GetCurrentUserDataUsingGET;
						this.user.email = user.email;
						this.user.phone = user.phone;
						this.user.firstName = user.firstName;
						this.user.lastName = user.lastName;
					}
					return of(this.user);
				})
			);
	}

	private pendingRegistrationType(): string {
		if (this.user && this.user.enrollmentStatus.pendingRegistrationType && this.user.enrollmentStatus.name === 'Started') {
			return this.user.enrollmentStatus.pendingRegistrationType;
		} else {
			return null;
		}
	}

	public ensureUserEx(): Observable<string> {
		if (userIsAvailable) {
			console.debug('ensureUserEx: user is available');
			const pendingRegistrationType = this.pendingRegistrationType();
			// if (pendingRegistrationType) {
			// 	return of(null);
			// }
			return of(null);
		}
		if (this.oauthService.hasValidIdToken()) {
			return this.onUserLoggedIn();
		} else {
			console.debug('ensureUserEx: user not available');
			return of();
		}
	}

	/*
  We do it in two steps:
  - get UI zones info (list of clients available for each zone: Client, Expert, HR expert, operator);
  based on last status (if exists) find out client/zone that should be shown (user.selectedCompanyId)\
  - get roles for current user/selected client (during enrolment we do not have a client so we will get only non-client roles, if any)

  From now on roles will be acquired 'on demand', at selected client change (see user.initialize and user.set selectedCompanyId)

  */
	/* return Observable is not used/should not be used unless changing the implementation*/
	private onUserLoggedIn(): Observable<string> {
		let user: User;
		return this.restService
			.query({
				query: userQueryEx2,
				fetchPolicy: 'network-only',
			})
			.pipe(
				catchError((error) => {
					console.debug('ensureUserEx.query error: ' + error.toString());
					console.error(error);
					return of(null);
				}),
				switchMap((res: any) => {
					let result: string;
					console.debug('EnsureUserEx.query result.Start: ' + new Date());
					if (res) {
						result = 'ok';

						user = plainToClass(User, res.data.auth.GetCurrentUserDataUsingGET);
						user.enrollmentStatus = plainToClass(EnrollmentStatus, res.data.accounts.enrollmentStatus);
						//user.roles = plainToClass<UserRole, object>(UserRole, this.processRoles(res.data.accounts.userRoles.expandedRoles));
						console.debug('EnsureUserEx.query result.zones: ' + new Date());
						user.zones = plainToClass<UserZone, object>(UserZone, res.data.accounts.zones.zones);
						const clientEid = user.selectedCompanyId;
						const ret = this.getUserRolesExpandedForClientObservable(clientEid);
						return ret;
					} else {
						return of(null);
					}
				}),
				switchMap((res: any) => {
					if (res) {
						console.debug('EnsureUserEx.query result.expandedRoles: ' + new Date());
						user.userRolesExpanded = res.data.accounts.userRolesExpanded;

						user.initialize(this);
						this.user = user;

						const pendingRegistrationType = this.pendingRegistrationType();
						// do not navigate to registration if processing finalize contract from PayU
						if (pendingRegistrationType && !this.router.url?.startsWith(AUTH_ROUTES.CLIENT_REGISTRATION_FINALIZE)) {
							this.router.navigate([AUTH_ROUTES.CLIENT_REGISTRATION]);
						}
						userIsAvailable = true;
						this.loggedIn.emit();
						console.debug('EnsureUserEx.query result.End: ' + new Date());
					} else {
						userIsAvailable = false;
						this.configService.page500();
					}
					return of(null);
				})
			);
	}
	public getUserRolesExpandedForClientObservable(clientEid: string): Observable<ApolloQueryResult<unknown>> {
		const ret = this.restService.query({
			query: Accounts_GetUserRolesExpandedForClientUsingGetDocument,
			variables: {
				clientEid: clientEid,
			},
			fetchPolicy: 'network-only',
		});
		return ret;
	}
	public ensureUserExOld(): Observable<string> {
		if (userIsAvailable) {
			console.debug('ensureUserEx: user is available');
			const pendingRegistrationType = this.pendingRegistrationType();
			if (pendingRegistrationType) {
				return of('registering');
			}
			return of('ok');
		}
		if (this.oauthService.hasValidIdToken()) {
			return this.onUserLoggedIn();
		} else {
			console.debug('ensureUserEx: user not available');
			return of('notok');
		}
	}

	private onUserLoggedInOld(): Observable<string> {
		return this.restService
			.query({
				query: userQuery2,
				fetchPolicy: 'network-only',
			})
			.pipe(
				catchError((error) => {
					console.debug('ensureUserEx.query error: ' + error.toString());
					console.error(error);
					return of('notok');
				}),
				switchMap((res: any) => {
					let result: string;
					console.debug('EnsureUserEx.query result.Start: ' + new Date());
					if (res) {
						result = 'ok';

						const user = plainToClass(User, res.data.auth.GetCurrentUserDataUsingGET);
						user.enrollmentStatus = plainToClass(EnrollmentStatus, res.data.accounts.enrollmentStatus);
						//user.roles = plainToClass<UserRole, object>(UserRole, this.processRoles(res.data.accounts.userRoles.expandedRoles));
						console.debug('EnsureUserEx.query result.expandedRoles: ' + new Date());
						user.userRolesExpanded = res.data.accounts.zones.userRolesExpanded;
						//user.roles = plainToClass<UserRole, object>(UserRole, this.processRoles(res.data.accounts.zones.userRolesExpanded.expandedRoles));
						console.debug('EnsureUserEx.query result.zones: ' + new Date());
						user.zones = plainToClass<UserZone, object>(UserZone, res.data.accounts.zones.zones);
						user.initialize(this);
						this.user = user;
						const pendingRegistrationType = this.pendingRegistrationType();
						// do not navigate to registration if processing finalize contract from PayU
						if (pendingRegistrationType && !this.router.url?.startsWith(AUTH_ROUTES.CLIENT_REGISTRATION_FINALIZE)) {
							result = 'registering';
							this.router.navigate([AUTH_ROUTES.CLIENT_REGISTRATION]);
							// const handler: RegistrationHandler = this.injector.get(pendingRegistrationType);
							// if (handler) {
							// 	handler.handle(this.user.enrollmentStatus.name);
							// 	result = 'registering';
							// }
						}

						// CM this is being set on selected company id subscriber, on setUser
						// this.checkUserCompanyStatus();
						// this.getCompanyInfo();
						userIsAvailable = true;
						this.loggedIn.emit();
						console.debug('EnsureUserEx.query result.End: ' + new Date());
					} else {
						result = 'notok';

						userIsAvailable = false;
						this.configService.page500();
					}
					return of(result);
				})
			);
	}

	public resetUser() {
		if (this.user) {
			this.user.finalize();
		}
		userIsAvailable = false;
	}

	public login(returnUrl: string = null): Observable<boolean> {
		if (!this.oauthService.hasValidIdToken()) {
			let params: any;
			let demoAccount = this.configService.useDemoAccount;
			if (demoAccount) {
				params = 'demo@keez.ro';
			} else {
				params = {};
				const accountingPartner = this.configService.accountingPartner;
				if (accountingPartner) {
					params.AccountingPartner = accountingPartner;
				}
			}
			// console.info("Redirecting to :", returnUrl, window.location.pathname);
			this.oauthService.initImplicitFlow(this.oauthService.state || JSON.stringify({ redirect: returnUrl || window.location.pathname }), params);
		}

		return of(true);

		// const self = this;
		// if (self.loggingOut) {
		//  return of(false);
		// }
		// return toObservable(self.discover())
		//  .pipe(
		//      switchMap(
		//          (discovered: boolean) => {
		//              if (discovered) {
		//                  if (!self.oauthService.hasValidIdToken()) {
		//                      let params: any;
		//                      let demoAccount = self.configService.useDemoAccount;
		//                      if (demoAccount) {
		//                          params = 'demo@keez.ro';
		//                      } else {
		//                          params = {};
		//                          const accountingPartner = self.configService.accountingPartner;
		//                          if (accountingPartner) {
		//                              params.AccountingPartner = accountingPartner;
		//                          }
		//                      }
		//                      self.oauthService.initImplicitFlow(self.oauthService.state || JSON.stringify({ redirect: window.location.pathname }), params);
		//                  }
		//                  return of(true);
		//                  //return self.ensureUser();
		//              }
		//              return of(false);
		//          }
		//      )
		//  );
	}

	public logout() {
		this.user = null;
		this.loggingOut = true;
		this.oauthService.logOut();
		this.loggedIn.emit();
	}

	//#region User data operations
	public updateMyData() {
		return this.restService
			.mutate(
				{
					mutation: updateMyDataMutationText,
					variables: {
						data: {
							showHelp: this.user.showHelp,
						},
					},
				},
				{ spinner: false }
			)
			.pipe(
				map((res: any) => {
					if (res) {
						return true;
					}
					return false;
				})
			);
	}

	public updateUserData(user: User): Observable<Auth_UserDataUpdateResponseRepresentationMutation> {
		const userForUpdateParam = Auth_UserDataUpdateDtoInput.fromUser(user);
		return this.restService
			.mutate({
				mutation: updateUserDataMutationText,
				variables: {
					userEid: user.externalId,
					user: userForUpdateParam,
					//user: classToPlain(user, { excludePrefixes: ["__"] })
				},
			})
			.pipe(
				map((res: { data: { auth: { UpdateUserDataUsingPUT: Auth_UserDataUpdateResponseRepresentationMutation } } }) => {
					if (res) {
						return res.data.auth.UpdateUserDataUsingPUT;
					}
					return null;
				})
			);
	}

	public changeMyPassword(oldPassword: string, newPassword: string) {
		return this.restService
			.mutate({
				mutation: changeMyPasswordMutationText,
				variables: {
					data: {
						oldPassword,
						newPassword,
					},
				},
			})
			.pipe(
				map((res: any) => {
					if (res) {
						return true;
					}
					return false;
				})
			);
	}

	public getZoneDisplayName(zoneCode: string): string {
		switch (zoneCode) {
			case PredefinedUserZone.Administrator:
				return 'Client';
			case PredefinedUserZone.Subcontractor:
				return 'Operator';
			case PredefinedUserZone.Expert:
				return 'Expert Contabil';
			case PredefinedUserZone.HrExpert:
				return 'Expert Salarizare';
		}
		return '';
	}

	public isInRole(role: string): boolean {
		if (this.user) {
			return this.user.isInRole(role);
		}
		return false;
	}
	/*
	public hasRole(role: string): boolean {
		if (this.user) {
			return this.user.userHasRole(role);
		}
		return false;
	}
  */
	public isInZone(zoneCode: string) {
		return this.user.isInZone(zoneCode);
	}
	//#endregion

	async getContractExceedingLocal(): Promise<Billing_SubscriptionExceedingRepresentation> {
		const ret = await this.getContractExceeding().toPromise();
		return of(ret.billing.getContractExceedingUsingGET).toPromise();
	}
	public getContractExceeding(): Observable<Billing_GetContractExceedingUsingGetQuery> {
		const clientEid = this.user.selectedCompanyId;
		const variables: Billing_GetContractExceedingUsingGetQueryVariables = {
			clientEid: clientEid,
		};
		return this.restService
			.query(
				{
					query: Billing_GetContractExceedingUsingGetDocument,
					variables: variables,
					fetchPolicy: 'no-cache',
				},
				{ spinner: false }
			)
			.pipe(
				map((res: ApolloQueryResult<Billing_GetContractExceedingUsingGetQuery>) => {
					if (res) {
						try {
							return res.data;
						} catch (err) {
							console.error(err.stack);
						}
					}
					return null;
				})
			);
	}
}
